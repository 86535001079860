import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_default = _resolveComponent("input-default")!
  const _component_drop_down = _resolveComponent("drop-down")!
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_drop_down, {
      class: "w-full",
      id: 'dropdown-' + _ctx.id,
      ref: "dropdownElement",
      dropdownPosition: _ctx.dropdownPosition,
      dropdownStyle: _ctx.dropdownStyle,
      transitionDuration: _ctx.transitionDuration,
      isDisabled: _ctx.isDisabled
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_input_default, {
            class: "w-full pointer-events-none",
            id: _ctx.id,
            name: _ctx.name,
            modelValue: _ctx.modelValue,
            isDisabled: _ctx.isDisabled,
            isInputDisabled: _ctx.isInputDisabled,
            tabIndex: _ctx.isInputDisabled ? -1 : 1,
            isRequired: _ctx.isRequired,
            label: _ctx.label,
            hasMeta: false,
            inputMeta: _ctx.inputMeta,
            metaStyle: _ctx.inputStyle,
            inputStyle: _ctx.inputStyle
          }, {
            append: _withCtx(() => [
              _renderSlot(_ctx.$slots, "append")
            ]),
            _: 3
          }, 8, ["id", "name", "modelValue", "isDisabled", "isInputDisabled", "tabIndex", "isRequired", "label", "inputMeta", "metaStyle", "inputStyle"])
        ])
      ]),
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _: 3
    }, 8, ["id", "dropdownPosition", "dropdownStyle", "transitionDuration", "isDisabled"]),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 0,
          inputMeta: _ctx.metaInformation,
          metaStyle: _ctx.inputStyle
        }, null, 8, ["inputMeta", "metaStyle"]))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_search = _resolveComponent("input-search")!
  const _component_feedback_message = _resolveComponent("feedback-message")!
  const _component_input_select_item = _resolveComponent("input-select-item")!
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_input_dropdown_wrapper = _resolveComponent("input-dropdown-wrapper")!

  return (_openBlock(), _createBlock(_component_input_dropdown_wrapper, {
    ref: "dropdownWrapperElement",
    class: _normalizeClass(["w-full select-none", _ctx.isDisabled ? 'cursor-default' : ' cursor-pointer']),
    id: _ctx.id,
    name: _ctx.name,
    modelValue: _ctx.computedDisplayValue,
    label: _ctx.label,
    hasMeta: _ctx.hasMeta,
    inputMeta: _ctx.inputMeta,
    inputStyle: _ctx.inputStyle,
    isDisabled: _ctx.isDisabled,
    isInputDisabled: _ctx.isInputDisabled,
    isRequired: _ctx.isRequired,
    metaInformation: _ctx.metaInformation,
    dropdownStyle: _ctx.dropDownStyle,
    onKeyup: _withModifiers(_ctx.handleKeyUpEvent, ["stop"]),
    onFocus: _ctx.openDropdown,
    onCloseDropdown: _ctx.resetIterator
  }, {
    content: _withCtx(() => [
      (_ctx.hasSearch)
        ? (_openBlock(), _createBlock(_component_input_search, {
            key: 0,
            class: "mb-1",
            id: _ctx.id + '-search',
            name: _ctx.name + '-search',
            modelValue: _ctx.searchValue,
            onInput: _ctx.emitUpdateSearchValue
          }, null, 8, ["id", "name", "modelValue", "onInput"]))
        : _createCommentVNode("", true),
      (_ctx.options == null || _ctx.options.length == 0)
        ? (_openBlock(), _createBlock(_component_feedback_message, {
            key: 1,
            message: _ctx.emptyOptionsMessage
          }, null, 8, ["message"]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createBlock(_component_input_select_item, {
              tabindex: "-1",
              key: index,
              id: _ctx.id + '-option-' + index,
              item: option,
              selectItemConfiguration: _ctx.selectItemConfiguration,
              selectItemStyle: _ctx.selectItemStyle,
              selectedItems: _ctx.computedModelValue,
              onClick: _withModifiers(($event: any) => (_ctx.onSelectOption(option)), ["stop","prevent"])
            }, null, 8, ["id", "item", "selectItemConfiguration", "selectItemStyle", "selectedItems", "onClick"]))
          }), 128))
    ]),
    append: _withCtx(() => [
      _createVNode(_component_icon_arrow, {
        color: 'grey',
        class: "cursor-pointer"
      })
    ]),
    _: 1
  }, 8, ["class", "id", "name", "modelValue", "label", "hasMeta", "inputMeta", "inputStyle", "isDisabled", "isInputDisabled", "isRequired", "metaInformation", "dropdownStyle", "onKeyup", "onFocus", "onCloseDropdown"]))
}

import { computed, ComputedRef } from "@vue/reactivity";
import {
    SelectItemConfigurationInterface,
    SelectItemStyleInterface,
} from "./input_select_interfaces";
import { checkOptionSelected, getOptionLabel } from "./input_select_helpers";
import { defineComponent } from "@vue/runtime-core";
import IconCheck from "@/components/icons/IconCheck.vue";
import { inputSelectItemDefaultStyle } from "./input_select_default_style";
import { getSpaceConfigStyle } from "@/components/space_config_style";
export default defineComponent({
    name: "InputSelectItem",
    components: {
        IconCheck,
    },
    props: {
        /**
         * Id Attribute for the option
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * String or Object representing the option
         */
        item: {
            type: [Object, String, Number],
            required: true,
        },
        /**
         * Configuration for the SelectItem including
         * labelColumn?: string;
         * valueColumn?: string;
         * imageColumn?: string;
         */
        selectItemConfiguration: {
            type: Object as () => SelectItemConfigurationInterface,
            default: {} as SelectItemConfigurationInterface,
        },
        /**
         * Style Configuration for SelectItem with possibility to adjust:
         *  textColor?: string;
         *  backgroundColor?: string;
         *  backgroundHoverColor?: string;
         *  backgroundActiveColor?: string;
         *  imageWidth?: string | number;
         *  paddingClasses?: string;
         *  checkIconColor?: string;
         */
        selectItemStyle: {
            type: Object as () => SelectItemStyleInterface,
            default: {} as SelectItemStyleInterface,
        },
        /**
         * Item(s) that is currently selected in the parent select
         */
        selectedItems: {
            type: [Number, String, Object, Array],
            default: null,
        },
    },
    setup(props) {
        /**
         * Merged style config with the final attributes
         */
        const finalInputSelectStyle: ComputedRef<SelectItemStyleInterface> =
            computed(function () {
                return {
                    ...inputSelectItemDefaultStyle,
                    ...getSpaceConfigStyle()?.inputSelectItem,
                    ...props.selectItemStyle,
                };
            });

        const computedImageSource: ComputedRef<string | null> = computed(() => {
            if (
                props.item == null ||
                typeof props.item == "string" ||
                props.selectItemConfiguration.imageColumn == null
            ) {
                return null;
            } else if (typeof props.item == "object") {
                return props.item[props.selectItemConfiguration.imageColumn];
            }
            return null;
        });

        /**
         * Checks if props.item is equal to or found in props.selectedItems
         * Note: stringify is used to allow comparison between proxys and objects
         */
        const isSelected: ComputedRef<boolean> = computed(() => {
            if (props.selectedItems == null) return false;
            if (Array.isArray(props.selectedItems)) {
                return (
                    props.selectedItems.findIndex((item: any) => {
                        return checkOptionSelected(
                            props.item,
                            item,
                            props.selectItemConfiguration.valueColumn
                        );
                    }) > -1
                );
            } else {
                return checkOptionSelected(
                    props.item,
                    props.selectedItems,
                    props.selectItemConfiguration.valueColumn
                );
            }
        });

        /**
         * Label that the item should be displayed as
         */
        const computedLabel: ComputedRef<string> = computed(() => {
            return getOptionLabel(
                props.item,
                props.selectItemConfiguration.labelColumn,
                props.selectItemConfiguration.valueColumn
            );
        });

        return {
            finalInputSelectStyle,
            computedImageSource,
            isSelected,
            computedLabel,
        };
    },
});


import { defineComponent } from "vue";
import { FeedbackMessageStyleInterface } from "./feedback_message_interfaces";
import useFeedbackMessageStyle from "./feedback_message_style";
import LoadingAnimation from "../loadingAnimation/LoadingAnimation.vue";

export default defineComponent({
  name: "FeedbackMessage",
  components: { LoadingAnimation },
  props: {
    /**
     * The message that should be displayed
     */
    message: {
      type: String,
      default: "Feedback Message",
      required: true,
    },

    /**
     * Configuration for  FeedbackMessage style with
     * following attribut:
     * {
     *      textColor?: string;
     *      iconColor?: string
     * }
     */
    feedbackMessageStyle: {
      type: Object as () => FeedbackMessageStyleInterface,
      default: {} as FeedbackMessageStyleInterface,
    },

    /**
     *  isLoading: showing a loading animation to indicate the user that the
     * request is still in progress
     *
     *  !isLoading: will show Feedback message
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    /**
     * import computed styles from FeedbackMessageStyle
     */
    const {
      computedFeedbackBackgroundStyle,
      computedFeedbackMessageStyle,
      computedAnimationColor,
    } = useFeedbackMessageStyle(props);

    return {
      computedFeedbackBackgroundStyle,
      computedFeedbackMessageStyle,
      computedAnimationColor,
    };
  },
});

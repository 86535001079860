import { SelectItemStyleInterface } from "./input_select_interfaces";

export const inputSelectItemDefaultStyle: SelectItemStyleInterface = {
    textColor: "black",
    backgroundColor: "white",
    backgroundHoverColor: "white-dark",
    backgroundActiveColor: "white-dark",
    paddingClasses: "py-2 px-4",
    imageWidth: 24,
    checkIconColor: "primary",
};

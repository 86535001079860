/**
 * Checks if [checkOption] is equal or has an equal valueColumn to [selectedOption]
 * Note: stringify is used to allow comparison between proxys and objects
 * @param checkOption - option that is to be checked
 * @param selectedOption - selected option that [checkOption] should be compared to
 * @param valueColumn - name of the attribute that determines by which value items should be compared
 */
export function checkOptionSelected(
    checkOption: Record<string, unknown> | string | number,
    selectedOption: Record<string, unknown> | string | number,
    valueColumn: string | undefined
): boolean {
    if (
        valueColumn == null ||
        !(typeof checkOption == "object" && typeof selectedOption == "object")
    ) {
        // compare whole options
        if (typeof checkOption == "object" && valueColumn != null) {
            return (
                JSON.stringify(checkOption[valueColumn]) ===
                JSON.stringify(selectedOption)
            );
        }
        return JSON.stringify(checkOption) === JSON.stringify(selectedOption);
    } else if (
        selectedOption &&
        typeof checkOption == "object" &&
        typeof selectedOption == "object"
    ) {
        // compare valuColumns of options
        return checkOption[valueColumn] == selectedOption[valueColumn];
    }
    return false;
}

/**
 * Extracts Label of an option
 * @param option - of which the label is wanted
 * @param {string} labelColumn - name of attribute that determines the label
 * @param {string} valueColumn - name of attribute that determines by which value items should be compared
 */
export function getOptionLabel(
    option: Record<string, unknown> | string | number,
    labelColumn: string | undefined,
    valueColumn: string | undefined
): string {
    if (typeof option == "string" || typeof option == "number") {
        // use item itself if no column is set
        return option as string;
    } else if (typeof option == "object" && labelColumn != null) {
        // use label column if set
        return option[labelColumn] as string;
    } else if (typeof option == "object" && valueColumn != null) {
        // use value column if label column not set
        return option[valueColumn] as string;
    }
    return JSON.stringify(option);
}

import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "flex w-full justify-center h-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center text-md h-12 p-1 px-3 mt-1 transition-all rounded-md", _ctx.computedFeedbackBackgroundStyle])
  }, [
    (!_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "icon", { key: 0 })
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["overflow-hidden whitespace-nowrap overflow-ellipsis", [_ctx.computedFeedbackMessageStyle]])
        }, _toDisplayString(_ctx.message), 3))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_loading_animation)
        ]))
  ], 2))
}
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "flex justify-self-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_check = _resolveComponent("icon-check")!

  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
    id: _ctx.id,
    class: ["flex flex-row items-center w-full rounded-md truncate focus:outline-none transition-all", `bg-${_ctx.finalInputSelectStyle.backgroundColor} 
    hover:bg-${_ctx.finalInputSelectStyle.backgroundHoverColor} 
    active:bg-${_ctx.finalInputSelectStyle.backgroundActiveColor} 
    focus:bg-${_ctx.finalInputSelectStyle.backgroundHoverColor} 
    ${_ctx.finalInputSelectStyle.paddingClasses}`]
  }), [
    (
                _ctx.selectItemConfiguration.imageColumn != null &&
                _ctx.computedImageSource != null
            )
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(`w-${_ctx.finalInputSelectStyle.imageWidth}`),
          src: _ctx.computedImageSource
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-1 text-left truncate", `text-${_ctx.finalInputSelectStyle.textColor}`])
    }, _toDisplayString(_ctx.computedLabel), 3),
    (_ctx.isSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_icon_check, {
            color: _ctx.finalInputSelectStyle.checkIconColor
          }, null, 8, ["color"])
        ]))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1))
}
import { computed, ComputedRef } from "vue";
import { FeedbackMessageStyleInterface } from "./feedback_message_interfaces";

export default function useFeedbackMessageStyle(props: {
    feedbackMessageStyle?: FeedbackMessageStyleInterface;
}): {
    computedFeedbackBackgroundStyle: ComputedRef<string>;
    computedFeedbackMessageStyle: ComputedRef<string>;
    computedAnimationColor: ComputedRef<string | undefined>;
} {
    /**
     * Default colors for animation and feedback text
     */

    const defaultStyle: FeedbackMessageStyleInterface = {
        animationColor: "primary",
        backgroundColor: "white-dark",
        textColor: "grey-dark",
    };

    /**
     * computed class for Message style
     */

    const computedFeedbackMessageStyle = computed(() => {
        let messageClass = "text-";

        // if color is not set
        if (
            props.feedbackMessageStyle?.textColor != null &&
            props.feedbackMessageStyle.textColor != ""
        )
            messageClass += props.feedbackMessageStyle?.textColor;
        else messageClass += defaultStyle.textColor;

        return messageClass;
    });

    /**
     * computed class for Background style
     */
    const computedFeedbackBackgroundStyle = computed(() => {
        let backgroundClass = "bg-";

        // if bgcolor is not set
        if (
            props.feedbackMessageStyle?.backgroundColor != null &&
            props.feedbackMessageStyle.backgroundColor != ""
        )
            backgroundClass += props.feedbackMessageStyle?.backgroundColor;
        else backgroundClass += defaultStyle.backgroundColor;

        return backgroundClass;
    });

    /**
     * computed class for animation color
     */
    const computedAnimationColor = computed(() => {
        if (
            props.feedbackMessageStyle?.animationColor != null &&
            props.feedbackMessageStyle?.animationColor != ""
        )
            return props.feedbackMessageStyle?.animationColor;
        else return defaultStyle.animationColor;
    });
    return {
        computedFeedbackBackgroundStyle,
        computedFeedbackMessageStyle,
        computedAnimationColor,
    };
}

import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "arrow1_bottom",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1779",
      "data-name": "Rechteck 1779",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "arrow1_bottom-2",
      "data-name": "arrow1_bottom",
      d: "M575.8,93.617a1,1,0,0,0-.924-.617h-12a1,1,0,0,0-.707,1.707l6,6a1,1,0,0,0,1.414,0l6-6A1,1,0,0,0,575.8,93.617Z",
      transform: "translate(-556.875 -85)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}

import { DropDownStyleInterface } from "@/components/helpers/dropDown/drop_down_interfaces";
import { checkOptionSelected, getOptionLabel } from "./input_select_helpers";
import { InputStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import InputDropdownWrapper from "../inputDropdownWrapper/InputDropdownWrapper.vue";
import FeedbackMessage from "@/components/helpers/feedbackMessage/FeedbackMessage.vue";
import InputSelectItem from "./InputSelectItem.vue";
import IconArrow from "@/components/icons/IconArrowBigBottom.vue";
import { computed, ComputedRef, ref } from "@vue/reactivity";
import { defineComponent } from "@vue/runtime-core";
import InputSearch from "../inputSearch/InputSearch.vue";
import {
    SelectItemConfigurationInterface,
    SelectItemStyleInterface,
} from "./input_select_interfaces";
import { useHelpersStore } from "@/store/helpers";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
import { storeToRefs } from "pinia";
export default defineComponent({
    components: {
        InputDropdownWrapper,
        FeedbackMessage,
        InputSelectItem,
        InputSearch,
        IconArrow,
    },
    name: "InputSelect",
    props: {
        /**
         * ID used to identify the underlying input
         */
        id: {
            type: [String, Number],
            required: true,
        },
        /**
         * HTML Input name attribute
         */
        name: {
            type: [String, Number],
            required: true,
        },
        /**
         * HTML Label for the Select, will be displayed above selection
         */
        label: {
            type: [String, Number],
            default: "",
        },
        /**
         * Disables the select completeley
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables the select input
         */
        isInputDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Sets required attribute if true
         */
        isRequired: {
            type: Boolean,
            default: false,
        },
        /**
         * Decides whether or not multiple items can be selected.
         * Multiselects will not close after click on item.
         */
        isMultiSelect: {
            type: Boolean,
            default: false,
        },
        /**
         * If true the modelValue will always be the value inside the valueColumn of the selected elements
         * e.g.: {id: 1, name: "Example"} with valueColumn = id, modelValue => 1
         */
        useValueColumnAsValue: {
            type: Boolean,
            default: false,
        },
        /**
         * modelValue are the currently selected item(s)
         */
        modelValue: {
            type: [Number, String, Object, Array],
        },
        /**
         * Options for the select as an array of Strings or Objects
         */
        options: {
            type: Array,
            default: null,
        },
        /**
         * Message that will be displayed given the case there are no options
         */
        emptyOptionsMessage: {
            type: String,
            default: "No options available",
        },
        /**
         * Controls whether or not the options are searchable
         * Filters by labeColumn if given
         */
        hasSearch: {
            type: Boolean,
            default: false,
        },
        /**
         * Value of search input, can be modeled via v-model:searchValue
         */
        searchValue: {
            type: String,
            default: null,
        },
        /**
         * if this is set the input saves space for the bottom meta container and the message can get displayed
         */
        hasMeta: {
            type: Boolean,
            default: true,
        },
        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * errorMessage?: string;
         * infoMessage?: string;
         * saveMessage?: string;
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },
        /**
         * Configuration for the SelectItem including
         * labelColumn?: string;
         * valueColumn?: string;
         * imageColumn?: string;
         */
        selectItemConfiguration: {
            type: Object as () => SelectItemConfigurationInterface,
            default: {} as SelectItemConfigurationInterface,
        },
        /**
         * Style Configuration for SelectItem with possibility to adjust:
         *  textColor?: string;
         *  backgroundColor?: string;
         *  backgroundHoverColor?: string;
         *  backgroundActiveColor?: string;
         *  imageWidth?: string | number;
         *  paddingClasses?: string;
         *  checkIconColor?: string;
         */
        selectItemStyle: {
            type: Object as () => SelectItemStyleInterface,
            default: {} as SelectItemStyleInterface,
        },
        /**
         * Configuration Object for Input Style with following attributes:
         *  labelColor?: string;
         *  backgroundColor?: string;
         *  backgroundFocusColor?: string;
         *  backgroundHoverColor?: string;
         *  inputTextColor?: string;
         *  errorColor?: string;
         *  saveColor?: string;
         *  infoColor?: string;
         */
        inputStyle: {
            type: Object as () => InputStyleInterface,
            default: {} as InputStyleInterface,
        },
        /**
         * Style Configuration for the Dropdown used by the Select:
         *  borderColor?: string;
         *  backgroundColor?: string;
         *  shadow?: string;
         *  widthClasses?: string;
         *  paddingClasses?: string;
         */
        dropDownStyle: {
            type: Object as () => DropDownStyleInterface,
            default: {} as DropDownStyleInterface,
        },
    },
    emits: [
        "update:modelValue",
        "update:searchValue",
        "change:searchValue",
        "selectOption",
    ],
    setup(props, ctx) {
        const dropdownWrapperElement = ref();
        const { removeErrorMessage } = useHelpersStore();
        const { errorMessages } = storeToRefs(useHelpersStore());
        /**
         * Iterator to keep track of focussed element
         */
        const iterator = ref(-1);
        const computedModelValue: ComputedRef<any | any[]> = computed(() => {
            if (
                props.useValueColumnAsValue == false ||
                props.selectItemConfiguration.valueColumn == null ||
                props.options == null
            ) {
                return props.modelValue;
            }
            if (!Array.isArray(props.modelValue)) {
                return props.options.find(
                    (option: any) =>
                        props.modelValue ==
                        option[props.selectItemConfiguration.valueColumn!]
                );
            } else {
                return props.options.filter((option: any) =>
                    (props.modelValue as any[]).includes(
                        option[props.selectItemConfiguration.valueColumn!]
                    )
                );
            }
        });
        /**
         * Adds option to modelValue if not already added and emits update:modelValue.
         * If the option was already added it will be removed from modelValue.
         *
         * @param {any} option - option that was selected
         */
        function onSelectOption(option: any): void {
            removeErrorMessage(props.id as string);
            if (props.isMultiSelect != true) {
                closeDropdown();
                // set modelValue to given option or null if option was selected twice
                if (
                    checkOptionSelected(
                        option,
                        computedModelValue.value,
                        props.selectItemConfiguration.valueColumn
                    )
                ) {
                    ctx.emit("update:modelValue", null);
                } else {
                    if (props.useValueColumnAsValue)
                        ctx.emit(
                            "update:modelValue",
                            option[props.selectItemConfiguration.valueColumn!]
                        );
                    else ctx.emit("update:modelValue", option);
                }
            } else {
                // initialize new modelValue
                let newModelValue = [];
                if (Array.isArray(computedModelValue.value)) {
                    newModelValue = computedModelValue.value;
                } else if (computedModelValue.value != null) {
                    newModelValue.push(computedModelValue.value);
                }
                // add option to modelValue or remove if option was selected twice
                let optionIndex = newModelValue.findIndex(
                    (selectedOption: any) => {
                        //  Note: stringify is used to allow comparison between proxys and objects
                        return checkOptionSelected(
                            option,
                            selectedOption,
                            props.selectItemConfiguration.valueColumn
                        );
                    }
                );
                if (optionIndex > -1) {
                    newModelValue.splice(optionIndex, 1);
                } else {
                    newModelValue.push(option);
                }
                if (props.useValueColumnAsValue) {
                    ctx.emit(
                        "update:modelValue",
                        newModelValue.map((newValueOption) => {
                            return newValueOption[
                                props.selectItemConfiguration.valueColumn!
                            ];
                        })
                    );
                } else ctx.emit("update:modelValue", newModelValue);
            }
            ctx.emit("selectOption", option);
        }

        function emitUpdateSearchValue(value: any) {
            const searchValue = value.target ? value.target.value : "";
            ctx.emit("update:searchValue", searchValue);
        }

        /**
         * Concatted labels of all selected options
         */
        const computedDisplayValue: ComputedRef<string | number> = computed(
            () => {
                if (computedModelValue.value == null) return "";
                else if (!Array.isArray(computedModelValue.value)) {
                    return getOptionLabel(
                        computedModelValue.value,
                        props.selectItemConfiguration.labelColumn,
                        props.selectItemConfiguration.valueColumn
                    );
                } else if (Array.isArray(props.modelValue)) {
                    let labels: string[] = computedModelValue.value.map(
                        (option: Record<string, unknown> | string | number) =>
                            getOptionLabel(
                                option,
                                props.selectItemConfiguration.labelColumn,
                                props.selectItemConfiguration.valueColumn
                            )
                    );
                    return labels.join(", ");
                }
                return "";
            }
        );

        const metaInformation = computed(() => {
            let metaInfos: MetaInformationInterface = {};
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (error.name == props.id && error.value) {
                    metaInfos.errorMessage = error.value;
                }
            });
            if (
                !metaInfos.errorMessage &&
                !metaInfos.infoMessage &&
                !metaInfos.saveMessage
            ) {
                return null;
            } else {

                return metaInfos;
            }
        });
        /**
         * Handles KeyUp Events for the whole select and enables
         * things like mouseless navigation for using the select
         * KeyUp = previous options
         * KeyDown = next option
         * Tab = next option & focus out of the select
         * @param {KeyboardEvent} e
         */
        function handleKeyUpEvent(e: KeyboardEvent): void {
            if (props.options == null || props.options.length == 0) return;
            if (e.key == "ArrowUp") {
                e.preventDefault();
                focusItem("previous");
            } else if (e.key == "ArrowDown") {
                e.preventDefault();
                focusItem("next");
            } else if (e.key == "Enter") {
                selectOptionByIndex(iterator.value);
            } else if (e.key == "Escape") {
                closeDropdown();
            }
        }
        /**
         * Select next or previous item in options list (only works for single select)
         * @param {string} direction - either "next" or "previous"
         */
        function focusItem(direction: string): void {
            if (direction == null) return;
            if (direction == "next") {
                if (iterator.value < 0) {
                    // select first element
                    iterator.value = 0;
                    focusOptionByIndex(0);
                } else if (
                    // select next element
                    iterator.value >= 0 &&
                    iterator.value < props.options.length - 1
                ) {
                    iterator.value += 1;
                    focusOptionByIndex(iterator.value);
                }
            } else {
                if (iterator.value < 0) {
                    // select last element
                    iterator.value = props.options.length - 1;
                    focusOptionByIndex(props.options.length - 1);
                } else if (
                    iterator.value >= 0 &&
                    iterator.value < props.options.length
                ) {
                    // select previous element
                    iterator.value -= 1;
                    focusOptionByIndex(iterator.value);
                }
            }
        }
        /**
         * Selects an item according to its index in props.options
         * @param {number} index
         */
        function selectOptionByIndex(index: number): void {
            focusOptionByIndex(index);
            if (
                props.options.length == 0 ||
                index == null ||
                props.options[index] == null
            )
                return;
            onSelectOption(props.options[index]);
        }
        /**
         * Sets focus on option according to its index in props.options
         * If index is out of bounds it will focus the select itsself
         */
        function focusOptionByIndex(index: number): void {
            const focusElement = document.getElementById(
                props.id + "-option-" + index
            );
            if (focusElement == null) {
                const selectElement = document.getElementById(`${props.id}`);
                selectElement?.focus();
            } else {
                focusElement.focus();
            }
        }
        /**
         * Opens Dropdown and focusses selected option if there is one
         */
        function openDropdown(): void {
            dropdownWrapperElement.value?.openDropdown();
            // wait until dropdown is open
            setTimeout(() => {
                const selectedIndex = props.options.findIndex((option: any) =>
                    checkOptionSelected(
                        option,
                        props.modelValue as any,
                        props.selectItemConfiguration.valueColumn
                    )
                );
                focusOptionByIndex(selectedIndex);
                iterator.value = selectedIndex;
            }, 150);
        }
        /**
         * Closes the dropdown by useing the close function if the wrapper element
         */
        function closeDropdown(): void {
            dropdownWrapperElement.value?.$refs?.dropdownElement?.closeDropDown();
        }
        /**
         * Resets the iterator
         */
        function resetIterator(): void {
            iterator.value = -1;
        }
        return {
            iterator,
            onSelectOption,
            dropdownWrapperElement,
            computedDisplayValue,
            computedModelValue,
            handleKeyUpEvent,
            openDropdown,
            closeDropdown,
            resetIterator,
            metaInformation,
            emitUpdateSearchValue,
        };
    },
});


import { defineComponent } from "vue";
import { InputStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import {
    DropdownPositionInterface,
    DropDownStyleInterface,
} from "../../helpers/dropDown/drop_down_interfaces";
import DropDown from "../../helpers/dropDown/DropDown.vue";
import InputDefault from "../inputDefault/InputDefault.vue";
import MetaHandler from "../metaHandler/MetaHandler.vue";

export default defineComponent({
    name: "InputWithDropdown",
    components: { DropDown, InputDefault, MetaHandler },
    props: {
        /**
         * id is necessary to uniquely identify this input
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML Input element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * HTML Input type
         * only use: text, number, password
         */
        type: {
            type: String,
            default: "text",
            validator: (value: string) => {
                return ["text", "number", "password"].includes(value);
            },
        },
        /**
         * displays ' *' behind the input label
         */
        isRequired: {
            type: Boolean,
            default: false,
        },
        /**
         *adds an overlay, so the input isn`t clickable anymore
         */
        isInputDisabled: {
            type: Boolean,
            default: true,
        },

        /**
         *adds an overlay if it is disabled
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * the label of the input, if it is undefined the input is getting centered
         */
        label: {
            type: String,
            required: false,
        },
        /**
         * metaInfos
         */
        metaInformation: {
            type: String,
            required: false,
        },
        /**
         * the displayed value of the input
         */
        modelValue: {
            required: true,
        },

        /**
         * Duration in Milliseconds for opening transition
         */
        transitionDuration: {
            type: Number,
            default: 150,
        },

        /**
         * Configuration Object for dropdown Style with
         * following attributes:
         * {
         *      ! borderColor?: string;
         *      ! backgroundColor?: string;
         *      ! shadow?: string;
         *      ! widthClasses?: string;
         * }
         */
        dropdownStyle: {
            type: Object as () => DropDownStyleInterface,
            default: {} as DropDownStyleInterface,
        },

        /**
         * if this is set the input saves space for the bottom meta container and the message can get displayed
         */
        hasMeta: {
            type: Boolean,
            default: true,
        },

        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * {
         *      ! errorMessage?: string;
         *      ! infoMessage?: string;
         *      ! saveMessage?: string;
         * }
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },

        /**
         * Configuration Object for Input Style with
         * following attributes:
         * {
         *      ! labelColor?: string;
         *      ! backgroundColor?: string;
         *      ! backgroundFocusColor?: string;
         *      ! backgroundHoverColor?: string;
         *      ! inputTextColor?: string;
         *      ! errorColor?: string;
         *      ! saveColor?: string;
         *      ! infoColor?: string;
         * }
         */
        inputStyle: {
            type: Object as () => InputStyleInterface,
            default: {} as InputStyleInterface,
        },
        /**
         * tabindex for the input
         */
        tabIndex: {
            type: Number,
            default: 0,
        },
        dropdownPosition: {
            type: Object as () => DropdownPositionInterface,
            default: {} as DropdownPositionInterface,
        },
    },
});
